import { gql } from '@apollo/client'

import {
  MutationCreateOneRecipeArgs,
  RecipeToIngredientInputObject,
  RecipeToRecipeInputObject,
  Unit,
} from 'api'

export const createRecipeMutationVariables = (
  name: string,
  imageUrl: string | undefined,
  section: string | undefined | null,
  unitCost: number,
  instructions: string,
  amount: number,
  unit: Unit | undefined | null,
  childRecipes: RecipeToRecipeInputObject[],
  ingredients: RecipeToIngredientInputObject[],
  kitchenId: number,
  removeIngredients?: number[],
  removeRecipes?: number[],
): { variables: MutationCreateOneRecipeArgs } => {
  // filter removed recipes
  const recipesToAdd = removeRecipes
    ? childRecipes.filter((recipe) => !removeRecipes?.includes(recipe.id))
    : childRecipes

  // filter removed ingredients
  const ingredientsToAdd = removeIngredients
    ? ingredients.filter(
        (ingredient) => !removeIngredients.includes(ingredient.id),
      )
    : ingredients

  return {
    variables: {
      childRecipes: recipesToAdd,
      data: {
        amount: amount,
        imageUrl: imageUrl,
        instructions: instructions,
        name: name,
        section: section,
        unitCost: unitCost,
        unitId: unit?.id,
      },
      ingredients: ingredientsToAdd,
      kitchenId,
    },
  }
}

export const createRecipeMutation = gql`
  mutation createRecipe(
    $data: CreateOneRecipeInputObject!
    $kitchenId: Int!
    $ingredients: [RecipeToIngredientInputObject!]
    $childRecipes: [RecipeToRecipeInputObject!]
    $allergens: [RecipeToAllergenInputObject!]
  ) {
    createOneRecipe(
      data: $data
      kitchenId: $kitchenId
      ingredients: $ingredients
      childRecipes: $childRecipes
      allergens: $allergens
    ) {
      _cursor
      id
      name
      unit {
        _cursor
        id
        name
        type
      }
      unitCost
    }
  }
`
/* eslint-disable graphql/required-fields */
export const getRecipeByIdQuery = gql`
  query getRecipeById($where: RecipeWhereInput!, $whereDish: DishWhereInput!) {
    recipes(where: $where) {
      ...recipeFragment
    }
    dishes(where: $whereDish) {
      _cursor
      id
      name
      gpTarget
      imageUrl
      overheads
      portion
      price
      type
      vat
      isDraft
      kitchen {
        _cursor
        id
        name
      }
      recipe {
        ...recipeFragment
      }
    }
  }

  fragment recipeFragment on Recipe {
    _cursor
    id
    status
    name
    amount
    imageUrl
    instructions
    name
    hasNoAllergens
    isDraft
    isUsed
    updatedAt
    allergens {
      id
      contains
      removable
      allergen {
        _cursor
        id
        imageUrl
        type
      }
    }
    unitCost
    unit {
      _cursor
      id
      name
      type
    }
    section
    kitchen {
      _cursor
      id
      name
    }
    dishes {
      _cursor
      id
    }
    ingredients {
      id
      createdAt
      amount
      unitCost
      unit {
        _cursor
        id
        name
        type
      }
      recipe {
        _cursor
        id
      }
      ingredient {
        _cursor
        id
        price
        unitPrice
        wastage
        conversionUnit
        conversionUnitType {
          _cursor
          id
          name
          type
          gramsPerUnit
        }
        conversionUnitValue
        product {
          _cursor
          id
          name
          code
          price
          status
          unit {
            _cursor
            id
            name
            type
          }
          unitValue
          packSize
          supplier {
            _cursor
            id
            name
          }
        }
      }
    }
    childRecipes {
      id
      createdAt
      parentRecipe {
        _cursor
        id
      }
      amount
      unit {
        _cursor
        id
        name
        type
      }
      unitCost
      childRecipe {
        _cursor
        id
        name
        amount
        unit {
          _cursor
          id
          name
          type
        }
        unitCost
      }
    }
  }
`
